import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { AnimationOnScroll } from 'react-animation-on-scroll'

import obi from './../assets/obi.png'
import panin from './../assets/panin.png'
import tkc from './../assets/tkc.png'
import download from './../assets/download.png'
import phoenix_logo from './../assets/use-cases/phoenix-logo.png'
import djarum from './../assets/use-cases/djarum.jpg'

const UseCase = () => {
  return(
    <>
      <Container className="pt-5 pb-5 wrap-hidden">
        {
          data.map((item, index) => {
            return(
              <div key={index} className="mb-5 studies">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.8} animatePreScroll={true} offset={0}>
                  <div className="header text-center-mobile">{item.title}</div>
                  <div className="text-center-mobile mt-2" dangerouslySetInnerHTML={{__html: item.desc}}/>
                </AnimationOnScroll>
                <Row className="mt-4">
                  <Col xs={12} md={5} lg={4} className="pb-3">
                    <AnimationOnScroll animateOnce={true} animateIn="animate__slideInLeft" duration={.8} animatePreScroll={true} offset={0}>
                      <div className='relative'>
                        <img src={item.image} className="img-studies border-image" alt="" />
                        {/* <img src={download} className="download pointer hover-zoom" alt="" /> */}
                      </div>
                    </AnimationOnScroll>
                  </Col>
                  <Col xs={12} md={7} lg={8} className="use-case">
                    <AnimationOnScroll animateOnce={true} animateIn="animate__slideInRight" duration={.8} animatePreScroll={true} offset={0}>
                      <div className="ps-4">
                        <div className="title">The Challenge</div>
                        <div className="mt-2" dangerouslySetInnerHTML={{__html: item.challenge}}/>

                        <div className="title mt-4">The Solution</div>
                        <p className="mt-2">{item.solution}</p>

                        {
                          item.channels &&
                          <Row className="mt-4">
                            <Col xs={'auto'}>
                              <div className="title" style={{width:90}}>Channels: </div>
                            </Col>
                            <Col>
                              <p className="flex-fill flex-wrap" style={{paddingTop:3}}>{item.channels}</p>
                            </Col>
                          </Row>
                        }

                        {
                          item.use_case &&
                          <Row className="mt-4">
                            <Col xs={'auto'}>
                              <div className="title" style={{width:90}}>Use Case: </div>
                            </Col>
                            <Col>
                              <p className="flex-fill flex-wrap" style={{paddingTop:3}}>{item.use_case}</p>
                            </Col>
                          </Row>
                        }
                      </div>
                    </AnimationOnScroll>
                  </Col>
                </Row>
                <div className="mt-4">
                  <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp" duration={.5} animatePreScroll={true} offset={0}>
                    <div className="box">
                      <Row>
                        <Col md={12} lg={'auto'}>
                          <div className="subheader me-4 mb-2">Key Highlight</div>
                        </Col>
                        <Col xs={12} md lg>
                          <Row>
                            {
                              item.key.map((dt, idx) => {
                                return(
                                  <Col xs={12} md={6} lg={4} key={idx} className="use-case mb-2">
                                    <Row>
                                      <Col xs="auto" style={{paddingRight:0}}>
                                        <div className="key-number">{idx+1}</div>
                                      </Col>
                                      <Col>
                                        <p style={{fontSize:14}}>{dt.content}</p>
                                      </Col>
                                    </Row>
                                  </Col>
                                )
                              })
                            }
                          </Row>
                        </Col>

                        
                      </Row>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            )
          })
        }
      </Container>
    </>
  )
}

export default UseCase

const data = [
  // {
  //   title: 'Phoenix',
  //   desc: `
  //     <p>It was all started in 2018, when PT EIS acquired a company under the name of 3G Indonesia. Starting its business as an Event Management Solution which earns the trust from various sectors, both private and government.</p>
  //     <br/>
  //     <p>In 2019, 3G Indonesia diversify its business tapping into the Outsourcing Agency, providing professional workforce for sales purposes, promotion and marketing, which serves organization/retail to help them maximize their profitability.</p>
  //     <br/>
  //     <p>Then using technology, at the same year in 2019, PT EIS developed an App called Phoenix for making the outsourcing tasks easier, simpler and real-time.</p>
  //     <br/>
  //     <ol>
  //       <li>This application can track the movement of inventory in real-time.</li>
  //       <li>Capable of integrating features for monitoring employee activities, including location and completed tasks.</li>
  //       <li>There is a notification system to inform the principal about important updates, such as changes in the status of goods or sales target achievements.</li>
  //       <li>Additionally, there is a feature to generate sales reports automatically and can be accessed anytime.</li>
  //       <li>Intuitive user interface (UI) design for easy use by field teams and principals using smart phones.</li>
  //     </ol>    
  //   `,
  //   image: phoenix_logo,
  //   challenge: 'To have more cost-effective, minimize fraud and manage data reporting for users to do direct selling merchandiser canvasing activities, such as: activity updates, product stock, sales stock, backwall displays, product conditions in retail stores in real-time.',
  //   solution: 'Phoenix: Retail Canvasing & Task Management Application/Platform.',
  //   channels: 'Mobile Android (available on play store: Minimum OS Lollypop, Minimum RAM 2GB), Web Based (Firefox, Internet Explorer, Opera, Google Chrome, Etc)',
  //   use_case: '',
  //   key: [
  //     {
  //       content: 'Easier for SPGs, Merchandisers, Surveyors, Canvassers during in the field to manage data collection for each product in a merchant or shop.'
  //     },
  //     {
  //       content: 'Time efficiency and effectiveness in carrying out tasks in the field.'
  //     },
  //     {
  //       content: "Data are sent in real time, so that the team leader or national PIC can see their team's activities, thereby eliminating delays in data reporting."
  //     },
  //     {
  //       content: "Management can control and view user activity and data in real time, minimizing the possibility of fraud."
  //     }
  //   ]
  // },
  {
    title: 'Djarum',
    desc: '',
    image: djarum,
    challenge: `
      <ul style="margin-left:-15px">
        <li>How to control and monitor effectively every activity in the field carried out by the Sales Team, Canvassers, SPG/SPB, Merchandisers and Surveyors.</li>
        <li>Almost 85% of their work is very vulnerable to fraud and human error.</li>
        <li>Need an integrated system that is detailed and real-time, and to reduce the vulnerability.</li>
      </ul>
    `,
    solution: 'Phoenix Application as an Automation Intelligence Services',
    channels: '',
    use_case: '',
    key: [
      {
        content: 'Realtime Control & Monitoring System. Management can control and view user activity and data in real time, minimizing the possibility of fraud.'
      },
      {
        content: 'Dynamic Dashboard Reporting System. '
      },
      {
        content: 'Easy Task Management to be Implement. Can be catered based on the team’s (Sales, Canvasser, SPG/SPB, Merchandiser, and Surveyor) needs.'
      },
      {
        content: "Easy to Access for the Monitoring and Reporting System, adaptable for android OS and Web Based."
      },
      {
        content: "The Application can be used online or offline."
      }
    ]
  },
  {
    title: 'Tokocrypto',
    desc: 'Business customers and consumers increasingly prefer to self-serve information by secure notification transaction through interaction between web touchpoint with notification message.',
    image: tkc,
    challenge: '<p>Provide seamless, personalized secure notification message anytime anywhere, and client can get the report realtime, monitor the events.</p>',
    solution: 'Mediacartz omni-channel messaging  can understand the client intent from messages send and cater to right services on demand, The Core system provide API to integrate with the client with secure connection and reliability to convey the message transaction from server to consumer, monitor the behaviour traffic to have early fraud detection.',
    channels: 'SMS',
    use_case: 'OTP Authentication',
    key: [
      {
        content: 'A true omni-channel, and Realtime message, reporting.'
      },
      {
        content: 'Text Services : Secure connection with personalize content and message Sender ID.'
      },
      {
        content: 'System reliability  with annual, quarterly, monthly, weekly, daily and hourly traffic pattern monitor.'
      }
    ]
  },
  {
    title: 'Panin Dubai Syariah Bank',
    desc: 'Business customers and consumers increasingly prefer to self-serve information by engaging with organisations through web and mobile apps, which are more convenient and offer access to real-time data whenever customers choose to do so.',
    image: panin,
    challenge: '<p>How to engage segmented customers with the new products and create leads from customer data engagement.</p>',
    solution: 'Mediacartz omni-channel with enriched by content management and user experience layout provide easiness for client to create engagement both of sms channel and web by providing features microsite, forms and tracking.',
    channels: 'SMS, Mobile Web',
    use_case: 'Landing Page, Message Notification, Message Blast',
    key: [
      {
        content: 'A true omni-channel, across conventional and digital channel.'
      },
      {
        content: 'CRM  data as data aggregator to maintain customer lead.'
      }
    ]
  },
  {
    title: 'Freshly Baked by Origin Bakery',
    desc: 'Food and Beverage business which provide healthier bread options and its pairings',
    image: obi,
    challenge: '<p>Provide comprehensive commerce capability to support end to end client business from downstream to upstream when running daily activity of inventory, stock distribution, transaction, reporting, insight and content management.</p>',
    solution: 'Mediacartz POS and Retail is more suitable to cater existing small medium retail system with unlimited boundary.',
    channels: 'SMS, Apps Android, iOS and Web',
    use_case: 'OTP Authentication, Offline POS Transaction, Online Commerce, Product Catalogue, Reporting & Monitoring',
    key: [
      {
        content: 'Realtime transaction and monitoring inventory and transaction.'
      },
      {
        content: 'Own infrastructure.'
      },
      {
        content: 'Easy to use technology for early adopters'
      }
    ]
  }
]