import '../../scss/ttp.scss'
import logo from '../../assets/ttp/logo.png'
import { Button, Form } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const DeleteAccount = () => {
  const submit = () => {
    MySwal.fire({
      title: 'Success',
      text: 'We will process within 30 days',
      icon: 'success',
      confirmButtonColor: '#D6262B'
    })
  }

  return(
    <div className='text-center ttp-container'>
      <div>
        <img src={logo} className='logo' alt=""/>
        <h3 className='mt-4'>Request Delete Account</h3>
        <Form className="mt-5">
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Email"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="password"
              placeholder="Password"
            />
          </Form.Group>
        </Form>
        <Button variant="ttp" style={{width:'100%'}} onClick={()=>submit()}>Delete Account</Button>
      </div>
    </div>
  )
}

export default DeleteAccount