import React, { useEffect, useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './../scss/banner_hero.scss'

import image1 from './../assets/hero/1.png'
import image2 from './../assets/hero/2.png'
import image3 from './../assets/hero/3.png'
import image4 from './../assets/hero/4.png'
import useWindowSize from "../helpers/UseWindowSize";

const settings = {
  dots: false,
  autoplay: true,
  infinite: true,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  arrows: false
};

const BannerHero = () =>{
  const [width, height] = useWindowSize();
  const [display, setDisplay] = useState(4);

  useEffect(()=>{
    if(width<992 && width>=768){
      setDisplay(3)
    }else if(width<768){
      setDisplay(2)
    }else{
      setDisplay(4)
    }
  }, [width])

  return(
    <div className="banner-wrapper">
      <div className="zoom">
        <div className="oval" />
        <Slider {...settings} slidesToShow={display}>
          <div><img src={image1} alt=""/></div>
          <div><img src={image2} alt=""/></div>
          <div><img src={image3} alt=""/></div>
          <div><img src={image4} alt=""/></div>
          <div><img src={image1} alt=""/></div>
          <div><img src={image2} alt=""/></div>
          <div><img src={image3} alt=""/></div>
          <div><img src={image4} alt=""/></div>
        </Slider>
        <div className="oval-bottom" />
      </div>
    </div>
  )
}

export default BannerHero