import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'react-bootstrap';
import logo from './assets/logo.png';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import './scss/style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import "animate.css/animate.min.css";

import Home from './pages/Home';
import About from './pages/About';
import Solution from './pages/Solution';
import Product from './components/Product';
import UseCase from './pages/UseCase';
import Contact from './pages/Contact';
import PageNotFound from './pages/PageNotFound';

import fb from './assets/fb.png'
import tw from './assets/tw.png'
import ig from './assets/ig.png'
import burgermenu from './assets/burger_menu.png'
import ScrollButton from './components/ScrollButton';

import c27001 from './assets/27001.png'
import c9001 from './assets/9001.png'
import SolutionDetail from './pages/SolutionDetail';
import DeleteAccount from './pages/ttp/DeleteAccount';

const Header = () => {
  const location = useLocation()

  return(
    <Navbar collapseOnSelect expand="lg" sticky='top'>
      <Container>
        <Navbar.Brand href="/"><img src={logo} className="logo-main" alt=""/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <img src={burgermenu} alt="" className='burger-menu'/>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <Nav.Link href="/" className={['me-5', location.pathname==='/'?'active':'']}>Home</Nav.Link>
            <Nav.Link href="/about-us" className={['me-5', location.pathname.indexOf('about-us') > -1?'active':'']}>About Us</Nav.Link>
            <Nav.Link href="/solutions-products" className={['me-5', location.pathname.indexOf('solutions-products') > -1?'active':'']}>Solutions & Products</Nav.Link>
            <Nav.Link href="/use-cases" className={['me-5', location.pathname.indexOf('use-cases') > -1?'active':'']}>Use Cases</Nav.Link>
            <Nav.Link href="/contact-us">
              <Button variant='brand'>Contact Us</Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const Footer = () => {
  return(
    <div className='footer'>
      <Container className='pt-5 pb-5'>
        <img src={logo} className="logo-main" alt=""/>
        <div className='mt-4 d-flex flex-row flex-row-mobile'>
          <div className='flex-grow-1'>
            <div className='text-white' style={{maxWidth:400}}>Bring you powerful advantages to accelerate your digital transformation</div>
          </div>
          <div>
            <div className='d-flex flex-row mt-2'>
              <img src={c27001} width={100} className='me-3' alt=""/>
              <img src={c9001} width={100} alt=""/>
            </div>
          </div>
        </div>
        <div className='hr-white mt-4 mb-4'/>
        <div className='text-white'>© {new Date().getFullYear()} PT. Exel Integrasi Solusindo</div>
      </Container>
    </div>
  )
}

const PageLayout = (page) => {
  return(
    <>
      <Header/>
      {page}
      <Footer/>
    </>
  )
}

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={PageLayout(<Home/>)}/>
        <Route exact path='/about-us' element={PageLayout(<About/>)}/>
        <Route exact path='/solutions-products' element={PageLayout(<Solution/>)}/>
        <Route exact path='/solutions-products/:slug' element={PageLayout(<SolutionDetail/>)}/>
        <Route exact path='/products' element={PageLayout(<Product/>)}/>
        <Route exact path='/use-cases' element={PageLayout(<UseCase/>)}/>
        <Route exact path='/contact-us' element={PageLayout(<Contact/>)}/>
        <Route exact path='/ttp/delete-account' element={<DeleteAccount/>}/>
        <Route path='*' element={<PageNotFound/>}/>
      </Routes>
      <ScrollButton/>
    </BrowserRouter>
  );
}

export default App;
