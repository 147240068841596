import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { AnimationOnScroll } from 'react-animation-on-scroll';

import about1 from './../assets/about1.png'
import about2 from './../assets/about2.png'

import enterprise1 from './../assets/enterprise/1.png'
import enterprise2 from './../assets/enterprise/2.png'
import enterprise3 from './../assets/enterprise/3.png'

import mg1 from './../assets/managament/1.png'
import mg2 from './../assets/managament/2.png'
import mg3 from './../assets/managament/3.png'
import mg11 from './../assets/managament/11.png'
import mg22 from './../assets/managament/22.png'
import mg33 from './../assets/managament/33.png'

import ig from './../assets/ig-red.png'
import tw from './../assets/tw-red.png'
import fb from './../assets/fb-red.png'
import li from './../assets/in-red.png'

const settingEnterprise = {
  dots: true,
  autoplay: true,
  infinite: true,
  slidesToScroll: 1,
  autoplaySpeed: 5000,
  slideToShow: 1,
  arrows: false
}

const About = () => {
  return(
    <div className="about wrap-hidden">
      <Container className="mt-5 mb-5">
        <Row style={{alignItems:'center'}}>
          <Col xs={12} md={5} className="img-about">
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInBottomLeft" duration={.8} animatePreScroll={true}>
              <img src={about1} alt="" />
            </AnimationOnScroll>
          </Col>
          <Col xs={12} md={7}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight" duration={.8} animatePreScroll={true}>
              <div className='text-center-mobile'><strong className='text-brand'>ABOUT US</strong></div>
              <p className="location mt-4 text-justify text-center-mobile"><span className="desc">Since our establishment in 2013,</span> our company has consistently focused on delivering cutting-edge telco solutions and mobile advertising services, forming the core pillars of our success. We take pride in providing our esteemed clients with top-notch telco solutions and effective mobile advertising strategies, resulting in numerous success stories that directly address our clients' needs.</p>
              <p className="location mt-4 text-justify text-center-mobile">As part of our commitment to continual growth and excellence, we are expanding and diversifying our product and service offerings to propel our clients further into the realm of digital and technological advancement. Our comprehensive portfolio now includes Telco, Advertising & Customer Engagement, Outsourcing & Talent Management, e-Commerce, and Tenant Management System.</p>
              <img src={about2} alt="" className="mobile mt-4" width="100%" />
              <p className="location mt-4 text-justify text-center-mobile">Moving forward, in an era dominated by technology and interconnected systems, cybersecurity plays a critical role in safeguarding sensitive information, networks, and systems from cyber threats and attacks. It encompasses a set of practices, technologies, and measures designed to protect digital data, networks, and infrastructure from unauthorized access, data breaches, and disruptions. Hence, in 2022, we also enter into the world of cyber security.</p>
              <p className="location mt-4 text-justify text-center-mobile">Our dynamic team comprises individuals with extensive experience in IT, telco, advertising creative industry, and cyber security. They stay abreast of the latest trends, ensuring that our solutions align seamlessly with the evolving needs of our clients in the realm of digital and technological advancements.</p>
              <p className="location mt-4 text-justify text-center-mobile">EIS is located at the heart of Jakarta, on the 38th floor of Centennial Tower, Unit H, Jl. Gatot Subroto, kavling 24-25, South Jakarta, 12930, Indonesia, our office serves as a central hub for innovation and collaboration.</p>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
      <div className='bg-pink mt-2 pt-5 pb-5'>
        <Container>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.8} animatePreScroll={true}>
            <div className="text-center"><strong className='text-brand'>VISION & MISSION</strong></div>
            <div className="text-center-mobile header mt-2 text-center">Vision</div>
            <div className="text-center-mobile desc text-center mt-2">Enabling a Future Sustainable Connected Business in Indonesia</div>
            <br/>
            <br/>
            <br/>
            <div className="text-center-mobile header mt-2 text-center">Mission</div>
            <div className="text-center-mobile desc text-center mt-2">Empowering Businesses to Achieve Sustainable and Safer Digital Operations Through Innovative Technology Products and Solutions</div>
          </AnimationOnScroll>
        </Container>
      </div>
      <div className="slide">
        <Container className="mt-5 mb-5 pt-5 pb-5">
          <div className='text-center-mobile'><strong className='text-brand'>EIS ENTERPRISE</strong></div>
          <Slider {...settingEnterprise}>
            {
              enterprise.map((item, index)=>{
                return(
                  <div key={index}>
                    <div className="text-center-mobile header mt-2">{item.title}</div>
                    <Row className="mt-4">
                      <Col xs={12} md={5} className="mobile">
                        <AnimationOnScroll animateOnce={true} animateIn="animate__flipInY" duration={1} offset={300}>
                          <img src={item.image} className="img-statistic mb-4" alt="" />
                        </AnimationOnScroll>
                      </Col>
                      <Col xs={12} md={7}>
                        {
                          item.data.map((dt, idx)=>{
                            return(
                              <div key={idx} className="box mb-2">
                                <strong>{dt.title}</strong>
                                <p className="mt-1">{dt.desc}</p>
                              </div>
                            )
                          })
                        }
                      </Col>
                      <Col xs={12} md={5} className="non-mobile">
                      <AnimationOnScroll animateOnce={true} animateIn="animate__flipInY" duration={1} offset={300}>
                          <img src={item.image} className="img-statistic" alt="" />
                        </AnimationOnScroll>
                      </Col>
                    </Row>
                  </div>
                )
              })
            }
          </Slider>
        </Container>
      </div>
      {/* <div className="bg-pink solution mg-wrap">
        <Container className="mt-5 pt-5 pb-5">
          <Row style={{alignItems:'center'}}>
            <Col xs={12} md={12} lg={4} className='pe-5'>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.7} offset={300}>
                <div className='text-center-mobile'><strong className='text-brand'>THE MAN BEHIND EIS</strong></div>
                <div className="text-center-mobile header mt-2">These are the people who run EIS</div>
              </AnimationOnScroll>
            </Col>
            <Col xs={12} md={12} lg={8}>
              <Row>
                {
                  management.map((item, index) => {
                    return(
                      <Col key={index} xs={12} md={4} className="mt-3 margin-top-mobile">
                        <AnimationOnScroll animateOnce={true} animateIn="animate__slideInRight" duration={1} delay={index*100} offset={300} style={{height:'100%'}}>
                          <div className="box bg-white d-flex flex-column" style={{borderRadius:20}}>
                            <img src={item.image} className="mg-img non-mobile" alt="" />
                            <div className="mobile mg-mobile">
                              <img src={item.image2} alt="" />
                            </div>
                            <div className="mt-3 text-center"><strong>{item.name}</strong></div>
                            <p className="mt-2 text-center flex-grow-1">{item.job}</p>
                            <div className="d-flex flex-row justify-content-center mt-4">
                              <a href={item.fb} className="social" target="_blank">
                                <img src={fb} />
                              </a>
                              <a href={item.in} className="social" target="_blank">
                                <img src={li} />
                              </a>
                              <a href={item.tw} className="social" target="_blank">
                                <img src={tw} />
                              </a>
                              <a href={item.ig} className="social" target="_blank">
                                <img src={ig} />
                              </a>
                            </div>
                          </div>
                        </AnimationOnScroll>
                      </Col>
                    )
                  })
                }
              </Row>
            </Col>
          </Row>
        </Container>
      </div> */}
    </div>
  )
}

export default About

const enterprise = [
  {
    title: 'Digital Core Capability',
    image: enterprise1,
    data: [
      {
        title: 'Experience',
        desc: 'Conquer context, content and channel to make CX your competitive advantage.'
      },{
        title: 'Insight',
        desc: 'Get to the source of the insights instead of relying on derivatives!.'
      },
      {
        title: 'Assure',
        desc: 'To be the catalyst for growth by solving complex problems, minimizing risks and building resilience for your businesses — digital-trust, assured.'
      },
      {
        title: 'Innovative',
        desc: 'Drive continuous improvement with ideas from a cross-section of industries and innovation ecosystems.'
      },
      {
        title: 'Accelerate',
        desc: 'Build a digital infrastructure that enables your enterprise to listen, learn and leverage entrenched knowledge to make better decisions with minimal intervention.'
      }
    ]
  },
  {
    title: 'Digital Operability',
    image: enterprise2,
    data: [
      {
        title: 'Design +',
        desc: 'For our clients, who are industry leaders, it is not about the next big thing, it’s simply everything that’s next. The next experience. The next innovation. The next possibility.'
      },
      {
        title: 'Learning +',
        desc: 'This is instrumental in helping our clients learn from data collected from various sources, connect them across silos using knowledge graphs and then encode this intelligence – leveraging new digital experience platforms like Mediacartz.'
      },
      {
        title: 'Automation +',
        desc: 'EIS exist to help businesses not just fulfil but also find new opportunities for intelligent automation. We use machine learning and analytics to take proactive action.'
      },
      {
        title: 'Agile +',
        desc: 'Our aim is to enable our clients to adopt agile, with governance that ensures frequent decision-making is managed by software. '
      },
      {
        title: 'Proximity +',
        desc: 'These bring to our client engagements the advantages of on-demand, in-person collaboration that is often key to accelerating enterprise-wide digital outcomes.'
      }
    ]
  },
  {
    title: 'Empowering Talent Transformations',
    image: enterprise3,
    data:[
      {
        title: 'Work Transformation',
        desc: 'By helping enterprises manage this work transformation, we help them not only remove barriers that would prevent them from fully participating in the modern economy, but also ensure they remain relevant in the future - as a living enterprise.'
      },
      {
        title: 'Workforce Transformation',
        desc: 'EIS have trained and integrated - at scale - liberal arts experts and design talent along with our developers to work together to build digital solutions for our clients. We are fully equipped to help our clients better prepare their own legacy workforce for a right and prosperous digital future.'
      },
      {
        title: 'Talent Transformation',
        desc: 'Co-creation by a diverse workforce requires an open\ and collaborative space. This calls for an agile, immersive and conducive environment for ideas to take\ shape into quick prototypes.'
      }
    ]
  }
]

const management = [
  {
    name: 'Lay Laberto',
    job: 'Chief Executive Officer',
    image: mg1,
    image2: mg11,
    fb: 'https://facebook.com',
    in: 'https://linkedin.com',
    tw: 'https://twitter.com',
    ig: 'https://instagram.com'
  },
  {
    name: 'Hilmar Stephen Innah',
    job: 'Chief Technology and Product Officer',
    image: mg2,
    image2: mg22,
    fb: 'https://facebook.com',
    in: 'https://linkedin.com',
    tw: 'https://twitter.com',
    ig: 'https://instagram.com'
  },
  {
    name: ' Arif Tirtosudiro',
    job: 'Chief Partnership and Marketing Officer',
    image: mg3,
    image2: mg33,
    fb: 'https://facebook.com',
    in: 'https://linkedin.com',
    tw: 'https://twitter.com',
    ig: 'https://instagram.com'
  }
]