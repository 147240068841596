import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { AnimationOnScroll } from 'react-animation-on-scroll';

import img1 from './../assets/products/1.jpg'
import img2 from './../assets/products/2.jpg'
import img3 from './../assets/products/3.jpg'
import img4 from './../assets/products/4.png'
import img9 from './../assets/products/9.png'
import img10 from './../assets/products/10.png'

const Product = () => {
  return(
    <>
      <Container className="pt-5 pb-5">
        <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn" duration={.8} animatePreScroll={true}>
          <div className="header text-center-mobile">Our Product</div>
          <p className="mt-3 text-center-mobile">Our work shows our ability and expertise. We enable your businesses with advanced tools and technologies to offer cutting-edge solutions.</p>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp" duration={.8} animatePreScroll={true}>
          <div className="box-wrap product mt-4">
            <Row>
              {
                data.map((item, index) => {
                  return(
                    <Col key={index} xs={6} md={6} lg={4} className="mt-3">
                      <div className="box">
                        <img src={item.image} alt="" />
                        <div className="body">
                          <div className="title">{item.title}</div>
                          <p className="desc mt-2">{item.short_desc}</p>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row>
          </div>
        </AnimationOnScroll>
      </Container>
    </>
  )
}

export default Product

const data = [
  {
    image: img1,
    title: "Cyber Security",
    short_desc: "It is an ongoing process that demands constant vigilance, investment, and collaboration to mitigate the risks associated with an ever-evolving digital landscape."
  },
  {
    image: img2,
    title: "Advertising & Customer Engagement",
    short_desc: "We make your brand relatable and meaningful through our customer engagement & advertising platform."
  },
  {
    image: img10,
    title: "Outsourcing & Talent Management",
    short_desc: "Providing professional manpower services for sales promotion and marketing purposes using technology."
  },
  {
    image: img4,
    title: "e-Commerce",
    short_desc: "Building solution to enhance your e-commerce results from acquisition, performance data, to retention."
  },
  {
    image: img9,
    title: "Tenant Management System",
    short_desc: "Helps real estate professionals using technology to manage and serve their tenants, including rental property owners, landlords, and property managers."
  },
  {
    image: img3,
    title: "Telco Solutions",
    short_desc: "Providing high quality project management, systems integration, system design and network implementation services."
  }
]