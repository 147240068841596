import React from "react"
import { Button, Container } from "react-bootstrap"

import "./../scss/404.scss"

const PageNotFound = () => {
  return(
    <div className="page404">
      <div className='bg-header'>
        <div className='box-dot'/>
        <Container className='fade-in-text'>
          <h5>404</h5>
          <div className='pt-2 mb-3 text-center header'>Page <span className='text-brand'>Not Found</span></div>
          <p className="text-center">We can’t seem to find the page you’re looking for.</p>
          <div className='text-center mt-3'>
            <a className='relative pointer' href="/" >
              <Button variant='brand'>Back to Home</Button>
            </a>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PageNotFound