import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import { AnimationOnScroll } from 'react-animation-on-scroll'
import emailjs from '@emailjs/browser'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import iconLoc from './../assets/ep_location-information.png'
import iconPhone from './../assets/bi_telephone.png'
import iconFax from './../assets/cil_fax.png'

const MySwal = withReactContent(Swal)

const Contact = () => {
  const formRef = useRef()
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  })
  const [error, setError] = useState({
    name: '',
    email: '',
    message: ''
  })

  const submit = () => {
    setLoading(true)

    var mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if(
      form.name==='' ||
      mailformat.test(form.email) === false ||
      form.message===''
    ){
      if(form.name===''){
        setError(state => ({...state, name: 'Required'}))
      }else{
        setError(state => ({...state, name: ''}))
      }

      if(mailformat.test(form.email) === false){
        setError(state => ({...state, email: 'Invalid email address'}))
      }else{
        setError(state => ({...state, email: ''}))
      }

      if(form.message===''){
        setError(state => ({...state, message: 'Required'}))
      }else{
        setError(state => ({...state, message: ''}))
      }

      setLoading(false)
      return false
    }

    setError({
      name: '',
      email: '',
      message: ''
    })

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, formRef.current, process.env.REACT_APP_PUBLIC_KEY)
    .then((result) => {
      setLoading(false)
      setForm({
        name: '',
        email: '',
        message: ''
      })
      MySwal.fire({
        title: 'Success',
        text: 'Thank for you for your response',
        icon: 'success',
        confirmButtonColor: '#D6262B'
      })
    }, (error) => {
      setLoading(false)
      MySwal.fire({
        title: 'Failed',
        text: error.text,
        icon: 'error',
        confirmButtonColor: '#D6262B'
      })
    });

  }

  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])

  return(
    <div className="contact">
      <Container className="pt-5 pb-5">
        <Row style={{alignItems:'center'}}>
          <Col xs={12} md={6} className="pe-5 mb-5">
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInDown" duration={.8} animatePreScroll={true}>
              <div className="help-header">
                <div className="relative">
                  <div className="box-yellow" />
                  <div className="header text-white">How can we help you?</div>
                  <p className="text-white mt-2">Call us, to discuss your environment and assess your requirements.</p>
                </div>
              </div>
              <p className="mt-5 mb-5">
                Ask how we can help you. Please use this form to contact us and we will get back to you as soon as possible. We would be happy to answer your questions and meet your requirements.
              </p>
              <div className="d-flex flex-row align-items-center">
                <img src={iconLoc} width="18px" alt="" />
                <p className="ms-3">
                  Centennial Tower, 38’th floor, Unit H
                  <br/>Jl. Gatot Subroto, kavling 24-25, Jakarta Selatan, 12930, Indonesia
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-3">
                <img src={iconPhone} width="18px" alt="" />
                <p className="ms-3">
                  021 22958146
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-3">
                <img src={iconPhone} width="18px" alt="" />
                <p className="ms-3">
                  081119077005
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-3">
                <img src={iconFax} width="18px" alt="" />
                <p className="ms-3">
                  sales@exel-int.com
                </p>
              </div>
            </AnimationOnScroll>
          </Col>
          <Col xs={12} md={6} className="ps-4">
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp" duration={.8} animatePreScroll={true}>
              <div className='text-center-mobile'><strong className='text-brand'>CONTACT US</strong></div>
              <div className="text-center-mobile header mt-2">Get In Touch</div>
              <p className="desc mt-4 text-center-mobile">Entrust us with the opportunity to address your challenges by providing customized solutions crafted specifically for your needs.</p>

              <Form className="mt-4" ref={formRef}>
                <Form.Group className="mb-3">
                  <Form.Label><strong>Full Name</strong> <span className="error">{error.name}</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type your name"
                    value={form.name}
                    onChange={(event) => setForm(state => ({...state, name: event.target.value}))}
                    disabled={loading}
                    name="name"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label><strong>Email</strong> <span className="error">{error.email}</span></Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Type your email"
                    value={form.email}
                    onChange={(event) => setForm(state => ({...state, email: event.target.value}))}
                    disabled={loading}
                    name="email"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label><strong>Message</strong> <span className="error">{error.message}</span></Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6} placeholder="Type your message"
                    value={form.message}
                    onChange={(event) => setForm(state => ({...state, message: event.target.value}))}
                    disabled={loading}
                    name="message"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Button variant="brand" disabled={loading} style={{width:'100%'}} onClick={()=>submit()}>
                    {
                      loading?(
                        <>
                          <Spinner animation="border" size="sm" className="me-2"/> Loading
                        </>
                      ):(
                        <>Submit</>
                      )
                    }
                  </Button>
                </Form.Group>
              </Form>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact