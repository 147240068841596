import React, {useState} from 'react'
import './../scss/scroll_button.scss'
import arrow from './../assets/arrow-up.png'

const ScrollButton = () =>{  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500){
      setVisible(true)
    } 
    else if (scrolled <= 500){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <div className='btn-scroll-wrap' style={{display: visible ? 'inline' : 'none'}}>
      <div 
        className='btn-scroll'
        onClick={scrollToTop}
      >
        <img src={arrow} alt="" />
      </div>
      <span>Back To Top</span>
    </div>
  );
}
  
export default ScrollButton;